<template>
	<div class="page-terms">
		<div class="form-h1">
			Privacy Policy
		</div>
		<div class="form-text">
			This Privacy Policy describes how your personal information is collected, used, and shared when you register
			Simplus membership.
		</div>
		<div class="form-title">
			Personal information we collect
		</div>

		<div class="form-text">
			When you visit the Site, we automatically collect certain information about your device, including
			information
			about your web browser, IP address, time zone, and some of the cookies that are installed on your device.

			Additionally, as you browse the Site, we collect information about the individual web pages, what websites
			or
			search terms referred you to the Site, and information about how you interact with the Site. We refer to
			this
			automatically-collected information as “Device Information”.
		</div>

		<div class="form-title">
			We collect Device Information using the following technologies:
		</div>
		<div class="form-text">
			- “Cookies” are data files that are placed on your device or computer and often include an anonymous unique
			identifier. For more information about cookies, and how to disable cookies, visit
			http://www.allaboutcookies.org.<br />
			- “Log files” track actions occurring on the Site, and collect data including your IP address, browser type,
			Internet service provider, referring/exit pages, and date/time stamps.<br />
			- “Web beacons”, “tags”, and “pixels” are electronic files used to record information about how you browse
			the Site.<br />
		</div>

		<div class="form-text" style="text-decoration: underline;">
			Additionally, when you register membership through the Site, we collect certain information from you,
			including
			your name, birthday, email address, and phone number. We refer to this information as “Member Information”.
		</div>
		<div class="form-text">
			When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device
			Information
			and Member Information.
		</div>
		<div class="form-text">
			How do we use your personal information?<br />
			We use the Member Information that we collect generally to fulfill any applications placed through the Site
			(including post-sales service, exclusive membership benefits, etc.). Additionally, we use this Member
			Information to:<br />
			- Communicate with you;<br />
			- When in line with the preferences you have shared with us, provide you with information or advertising
			relating to our products or services.<br />
		</div>
		<div class="form-text">
			We use the Device Information that we collect to help us screen for potential risk and fraud (in particular,
			your IP address), and more generally to improve and optimize our Site (for example, by generating analytics
			about how our members browse and interact with the Site, and to assess the success of our marketing and
			advertising campaigns).
		</div>
		<div class="form-title">
			Sharing you personal Information
		</div>
		<div class="form-text">
			We share your Personal Information with third parties to help us use your Personal Information, as described
			above. For example, we use S to power our online store--you can read more about how Shopify uses your
			Personal Information here: https://www.shopify.com/legal/privacy.<br />
			We also use Google Analytics to help us understand how our customers use the Site<br />
			-- you can read more about how
			Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/.
			You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
		</div>
		<div class="form-text">
			Finally, we may also share your Personal Information to comply with applicable laws and regulations, to
			respond
			to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect
			our
			rights.
		</div>
		<div class="form-title">
			Behavioral advertising
		</div>
		<div class="form-text">
			As described above, we use your Personal Information to provide you with targeted advertisements or
			marketing
			communications we believe may be of interest to you. For more information about how targeted advertising
			works,
			you can visit the Network Advertising Initiative’s (“NAI”) educational page at
			http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
		</div>
		<div class="form-text">
			You can opt out of targeted advertising by using the links below:<br />
			- Facebook: https://www.facebook.com/settings/?tab=ads<br />
			- Google: https://www.google.com/settings/ads/anonymous<br />
			- Bing: https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads<br />
		</div>
		<div class="form-text">
			Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s
			opt-out
			portal at: http://optout.aboutads.info/.
		</div>
		<div class="form-title">
			Do not track
		</div>
		<div class="form-text">
			Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track
			signal
			from your browser.
		</div>
		<div class="form-title">
			Your rights
		</div>
		<div class="form-text">
			You have the right to access personal information we hold about you and to ask that your personal
			information be
			corrected, updated, or deleted. If you would like to exercise this right, please contact us through the
			email. 
		</div>
		<div class="form-title">
			Data retention 
		</div>
		<div class="form-text">
			When you register membership through the Site, we will maintain your Personal Information for our records
			unless
			and until you ask us to delete this information. 
		</div>
		<div class="form-title">
			Changes 
		</div>
		<div class="form-text">
			We may update this privacy policy from time to time in order to reflect, for example, changes to our
			practices
			or for other operational, legal or regulatory reasons. 
		</div>
		<div class="form-title">
			Minors 
		</div>
		<div class="form-text">
			The Site is not intended for individuals under the age of 18 . 
		</div>
		<div class="form-title">
			Contact us 
		</div>
		<div class="form-text">
			For more information about our privacy practices, if you have questions, or if you would like to make a
			complaint, please contact us by e-mail: service@simplus.online or online shop/social platform chat.
		</div>
	</div>
</template>

<script>
	export default {
		created() {
			localStorage.setItem('NationRegShow', true)
		}
	};
</script>

<style lang="less">
	.page-terms {
		background: #FFFFFF;
		padding: 12px;

		.form-h1 {
			color: #000000;
			font-size: 36px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 600;
			padding-bottom: 12px;
		}

		.form-title {
			color: #000000;
			font-size: 18px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 600;
			padding-bottom: 12px;
		}

		.form-text {
			color: #000000;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			padding-bottom: 12px;
		}
	}
</style>